// Link: https://stackoverflow.com/questions/8817394/javascript-get-deep-value-from-object-by-passing-path-to-it-as-string
export const getDeepObjectValue = (obj, path) => {
	var paths = path.split('.'), current = obj, i;

	for (i = 0; i < paths.length; ++i) {
		if (current[paths[i]] === undefined) {
			return undefined;
		} else {
			current = current[paths[i]];
		}
	}

	return current;
};

export const getLicenseType = (licenseType) => {
	switch (licenseType) {
		case 'temporary':
			return 'Временная';
		case 'temporary_after_activation':
			return 'Временная, с момента активации';
		case 'unlimited':
			return 'Неограниченная';
		case 'countered':
			return 'По количеству запусков';
		default:
			return 'Неизвестно';
	}
};

export const getUserRole = (roleType) => {
	switch (roleType) {
		case 'admin':
			return 'Администратор';
		case 'user':
			return 'Пользователь';
		case 'corp':
			return 'Организация';
		case 'child':
			return 'Пользователь организации';
		default:
			return 'Неизвестно';
	}
};

export const getUserFromUserList = (userList, userId) => {
	return userList.find(e => e.id === userId);
};

export const getUserFromUserListByEmail = (userList, userEmail) => {
	return userList.find(e => e.email === userEmail);
};

export const getCompanyFromCompanyList = (companyList, companyId) => {
	return companyList.find(e => e.id === companyId);
};

export const getCompanyFromCompanyListByEmail = (companyList, companyEmail) => {
	return companyList.find(e => e.email === companyEmail);
};

export const getProductFromProductList = (productList, productId) => {
	return productList.find(e => e.id === productId);
};

export const getLicenseFromLicenseList = (licenseList, licenseId) => {
	return licenseList.find(e => e.id === Number(licenseId));
};

export const capitalizeFirst = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const checkDateWithToday = (date) => {
	date = new Date(date);

	var today = new Date();
	today.setHours(0, 0, 0, 0);

	return date >= today;
};

export const convertDateStringFromUTCToLocal = (dateString) => {
	const date = new Date(dateString);
	const localTimezoneOffset = date.getTimezoneOffset();
	const time = date.getTime();
	const shiftedDate = new Date(time - localTimezoneOffset * 60 * 1000);
	return `${shiftedDate.toLocaleDateString()} ${shiftedDate.toLocaleTimeString()}`;
}
