import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuthDispatch, logoutUser, useAuthState } from 'Context';

export default function Header() {
	const dispatch  = useAuthDispatch();
	const authState = useAuthState();

	function toggleTheme() {
		document.getElementById('toggleTheme').click();
	}

	const handleLogout = () => {
		logoutUser(dispatch);

		const script = document.getElementById('themeToggle');
		if (script) {
			document.body.removeChild(script);
		}

		return <Redirect to="/login" />;
	};

	return (
		<>
			<nav className="navbar navbar-expand-md navbar-dark bg-dark">
				<div className="container-fluid">
					<a href="/" className="navbar-brand text-decoration-none text-info">Система лицензирования и логирования</a>

					{authState.isAuthenticated
						? <>
							<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
								<span className="navbar-toggler-icon"></span>
							</button>

							<div className="vr ms-3 me-1 text-white"></div>

							<div className="collapse navbar-collapse" id="navbarHeader">
								<ul className="navbar-nav me-auto mb-2 mb-md-0">
									<li className="nav-item dropdown">
										<a className="nav-link dropdown-toggle" href="{() => false}" id="dropdownNavbarLicense" data-bs-toggle="dropdown" aria-expanded="false">
											<i className="bi-key"></i> Лицензии
										</a>
										<ul className="dropdown-menu" aria-labelledby="dropdownNavbarLicense">
											<li><a className="dropdown-item" href="/license"><i className="bi-card-list pe-1"></i> Список</a></li>
											<li><a className="dropdown-item" href="/license/add"><i className="bi-plus-square-dotted pe-1"></i> Создание</a></li>
										</ul>
									</li>
									<li className="nav-item dropdown">
										<a className="nav-link dropdown-toggle" href="{() => false}" id="dropdownNavbarProduct" data-bs-toggle="dropdown" aria-expanded="false">
											<i className="bi-bag"></i> Продукты
										</a>
										<ul className="dropdown-menu" aria-labelledby="dropdownNavbarProduct">
											<li><a className="dropdown-item" href="/product"><i className="bi-card-list pe-1"></i> Список</a></li>
											<li><a className="dropdown-item" href="/product/add"><i className="bi-bag-plus pe-1"></i> Создание</a></li>
										</ul>
									</li>
									<li className="nav-item dropdown">
										<a className="nav-link dropdown-toggle" href="{() => false}" id="dropdownNavbarUser" data-bs-toggle="dropdown" aria-expanded="false">
											<i className="bi-person"></i> Пользователи
										</a>
										<ul className="dropdown-menu" aria-labelledby="dropdownNavbarUser">
											<li><a className="dropdown-item" href="/user"><i className="bi-card-list pe-1"></i> Список</a></li>
											<li><a className="dropdown-item" href="/user/add"><i className="bi-person-plus pe-1"></i> Создание</a></li>
										</ul>
									</li>
									<li className="nav-item dropdown">
										<a className="nav-link dropdown-toggle" href="{() => false}" id="dropdownNavbarCompany" data-bs-toggle="dropdown" aria-expanded="false">
											<i className="bi-people"></i> Организации
										</a>
										<ul className="dropdown-menu" aria-labelledby="dropdownNavbarCompany">
											<li><a className="dropdown-item" href="/company"><i className="bi-card-list pe-1"></i> Список</a></li>
											<li><a className="dropdown-item" href="/company/add"><i className="bi-person-plus pe-1"></i> Создание</a></li>
										</ul>
									</li>
									<li className="nav-item dropdown">
										<a className="nav-link dropdown-toggle" href="{() => false}" id="dropdownNavbarStats" data-bs-toggle="dropdown" aria-expanded="false">
											<i className="bi-graph-up"></i> Статистика
										</a>
										<ul className="dropdown-menu" aria-labelledby="dropdownNavbarStats">
											<li><a className="dropdown-item" href="/stats/license"><i className="bi-card-checklist pe-1"></i> Статистика использования лицензий</a></li>
											<li><a className="dropdown-item" href="/stats/logs"><i className="bi-graph-down pe-1"></i> Статистика по логам</a></li>
											<li><a className="dropdown-item" href="/stats/errors"><i className="bi-bug pe-1"></i> Статистика по ошибкам</a></li>
										</ul>
									</li>
								</ul>
								<div className="navbar-nav dropdown">
									<a className="nav-link dropdown-toggle" href="{() => false}" id="dropdownCurrentUser" data-bs-toggle="dropdown" aria-expanded="false">
										<i className="bi-person-badge"></i> {authState.currentUser.userName}
									</a>
									<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownCurrentUser">
										<li>
											<a href="#toggle" className="dropdown-item" onClick={toggleTheme}>
												<div className="d-inline-flex form-check form-switch tooltip-demo">
													<input className="form-check-input" type="checkbox" id="toggleTheme" />
												</div>
												Темная тема
											</a>
										</li>
										<li><hr className="dropdown-divider" /></li>
										<li>
											<button className="dropdown-item" onClick={handleLogout}>Выйти</button>
										</li>
									</ul>
								</div>
							</div>
						</>
						:null
					}
				</div>
			</nav>
		</>
	);
}
