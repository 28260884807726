import React, { useState, useEffect } from 'react';
import { Table as BTable } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { GetUser } from 'Api';
import { getUserRole } from 'Helpers';
import NotFound from 'Components/NotFound';
import Loading from 'Components/Loading';

function UserView(props) {
	const [user, setUser]       = useState('');
	const [loading, setLoading] = useState(true);

	const params = useParams();

	useEffect(() => {
		(async () => {
			const user = await GetUser(params.userId);
			setUser(user);

			setLoading(false);
		})();
	}, [params.userId]);

	if (loading) {
		return <Loading />;
	}

	if (user) {
		return (
			<div className="container-fluid">
				<h3>Просмотр пользователя - {user.username}</h3>
				<BTable>
					<tbody>
						<tr>
							<td>ID</td>
							<td>{user.id}</td>
						</tr>
						<tr>
							<td>Пользователь</td>
							<td>{user.username}</td>
						</tr>
						<tr>
							<td>Email</td>
							<td>{user.email}</td>
						</tr>
						<tr>
							<td>Роль</td>
							<td>{getUserRole(user._role)}</td>
						</tr>
						<tr>
							<td>Статус</td>
							<td>{user.is_banned === true ? 'Заблокирован' : 'Активен'}</td>
						</tr>
					</tbody>
				</BTable>
			</div>
		);
	} else {
		return <NotFound />;
	}
}

export default UserView;
