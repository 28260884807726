import React from 'react';
import Button from 'react-bootstrap/Button';

function ViewButton(props) {
	return (
		<Button
			href={props.href}
			variant="outline-info"
			className={`${props.className ? props.className + ' ' : ''}me-1`}
			size={props.size ? props.size : ''}
			title={props.title}
		>
			<i className="bi-eye"></i>{props.withCaption ? ' Просмотр' : ''}
		</Button>
	);
}

export default ViewButton;
