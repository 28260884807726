import * as session from 'Session';
import { ApiRequest } from 'Api';

export async function loginUser(dispatch, payload) {
	try {
		dispatch({
			type: 'REQUEST_LOGIN',
		});

		await ApiRequest('user.login', payload).then(response => {
			const jwtToken = response.data;
			session.setJwtToken(jwtToken);

			ApiRequest('user.info').then(userInfoResponse => {
				const currentUser = userInfoResponse.data;

				if (userInfoResponse.data.roleName === 'admin') {
					dispatch({
						type:        'LOGIN_SUCCESS',
						jwtToken:    jwtToken,
						currentUser: currentUser,
					});

					session.setCurrentUser(currentUser);

					return currentUser;
				}
				else {
					session.removeJwtToken();

					dispatch({
						type:  'LOGIN_ERROR',
						error: 'Ошибка авторизации: нет права доступа',
					});

					console.log('Response "user.login" error. Role:', userInfoResponse.data.roleName);
				}
			}).catch(userInfoError => {
				let errorMessage;

				if (userInfoError.userInfoResponse.status === 401) {
					errorMessage = 'Ошибка сервера авторизации';
				}
				else {
					errorMessage = 'Неизвестная ошибка сервера (' + userInfoError.userInfoResponse.status + ')';
				}

				session.removeJwtToken();

				dispatch({
					type:  'LOGIN_ERROR',
					error: errorMessage,
				});

				console.log('Response "user.info" error:', userInfoError.userInfoResponse.statusText);

				return;
			});
		}).catch(error => {
			let errorMessage;

			if (error.response.status === 500 && error.response.data.error) {
				errorMessage = 'Ошибка авторизации: неверные логин или пароль';
			} else if (error.response.status === 400 && error.response.data.errors) {
				errorMessage = 'Ошибка сервера';

				var key;
				var counter = 0;

				for (key in error.response.data.errors) {
					errorMessage += counter > 0 ? ', ' : ': ';

					errorMessage += '"' + error.response.data.errors[key] + '"';
					counter++;
				}
			} else {
				errorMessage = 'Неизвестная ошибка сервера (' + error.response.status + ')';
			}

			dispatch({
				type:  'LOGIN_ERROR',
				error: errorMessage,
			});

			console.log('Response "user.login" error:', (error.response.data.error ? error.response.data.error : error.response.data.errors));

			return;
		});
	} catch(error) {
		dispatch({
			type:  'LOGIN_ERROR',
			error: error,
		});

		console.log('LOGIN ERROR:', error);
	}
}

export async function logoutUser(dispatch) {
	dispatch({
		type: 'LOGOUT',
	});

	session.removeCurrentUser();
	session.removeJwtToken();
}
