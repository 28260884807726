import * as session from 'Session';

const currentUser = session.getCurrentUser();
const jwtToken    = session.getJwtToken();

export const initialState = {
	currentUser:     '' || currentUser,
	jwtToken:        '' || jwtToken,
	isAuthenticated: Boolean(jwtToken),
	loading:         false,
	errorMessage:    null,
};

export const ActionReducer = (initialState, action) => {
	switch (action.type) {
		case 'REQUEST_LOGIN':
			return {
				...initialState,
				loading: true,
			};

		case 'LOGIN_SUCCESS':
			return {
				...initialState,
				currentUser:     action.currentUser,
				jwtToken:        action.jwtToken,
				isAuthenticated: true,
				loading:         false,
			};

		case 'LOGIN_ERROR':
			return {
				...initialState,
				loading:      false,
				errorMessage: action.error,
			};

		case 'LOGOUT':
			return {
				...initialState,
				currentUser:     '',
				jwtToken:        '',
				isAuthenticated: false,
				errorMessage:    null,
			};

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
