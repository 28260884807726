import React, { useEffect } from 'react';

import { useAuthState } from 'Context';

export default function Footer() {
	const authState = useAuthState();

	useEffect(() => {
		if (authState.isAuthenticated) {
			const script = document.createElement('script');

			script.src   = '/js/theme.js';
			script.async = true;
			script.id    = 'themeToggle';

			document.body.appendChild(script);

			return () => {
				const script = document.getElementById('themeToggle');
				if (script) {
					document.body.removeChild(script);
				}
			}
		}
	}, [authState.isAuthenticated]);

	return (
		<footer className="py-3 my-4">
			<p className="text-center text-muted">&copy; ИИР 2021</p>
		</footer>
	);
}
