import React from 'react';

function StatsErrors(props) {
	return (
		<div style={{ height: '100vh' }}>
			<h3 className="container-fluid">Статистика по ошибкам</h3>
				<iframe
					src="https://dev.kibana.launcher.rehab.smuit.ru/app/dashboards#/view/ae7c81d0-da56-11eb-8bc5-ffaa80479c23?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cto%3Anow))&show-time-filter=true"
					width="100%"
					frameBorder="0"
					scrolling="no"
					style={{ height: '100vh' }}
					title="Elastic"
				></iframe>
		</div>
	);
}

export default StatsErrors;
