import React, { useState, useEffect } from 'react';

import Table from 'Components/Table';
import { GetCompanyList } from 'Api';
import Loading from 'Components/Loading';
import ViewButton from 'Components/Buttons/ViewButton';

const tableHead = [
	{ accessor: 'id',           Header: 'ID' },
	{ accessor: 'username',     Header: 'Название' },
	{ accessor: 'email',        Header: 'Email' },
	{ accessor: 'max_children', Header: 'Максимум пользователей' },
	{ accessor: 'actions',      Header: 'Действие' },
];

function CompanyList(props) {
	const [companyList, setCompanyList] = useState('');

	useEffect(() => {
		(async () => {
			const companyList = await GetCompanyList();
			setCompanyList(companyList.items);
		})();
	}, []);

	if (companyList) {
		let preparedCompanyList = [];

		let length = companyList.length;
		for (var i = 0; i < length; i++) {
			preparedCompanyList[i] = {
				id:           companyList[i].id,
				username:     companyList[i].username,
				email:        companyList[i].email,
				max_children: companyList[i].max_children,
				lineThrough:  companyList[i].is_banned,
				actions:      <ViewButton
								href={'/company/view/' + companyList[i].id}
								size="sm"
								title={'Просмотр организации ' + companyList[i].username}
							  />,
			};
		}

		return (
			<div className="container-fluid">
				<h3>Список организаций</h3>
				<Table columns={tableHead} data={preparedCompanyList} />
			</div>
		);
	} else {
		return <Loading />;
	}
}

export default CompanyList;
