import React from 'react';
import { Row, Col, Card, Button, ListGroup } from 'react-bootstrap';

import { useAuthState } from 'Context';

function Dashboard(props) {
	const authState = useAuthState();

	return (
		<div className="container-fluid">
			<h3>Панель управления ({authState.currentUser.userName})</h3>
			<Row>
				<Col md sm={6} xs={12} className="d-flex align-items-stretch mb-3">
					<Card>
						<Card.Body className="d-flex flex-column">
							<Card.Title><i className="bi-key"></i> Лицензии</Card.Title>
							<Card.Text>
								Выпуск новых лицензий, обновление и отключение существующих.
							</Card.Text>
							<Button variant="light" href="/license" className="text-nowrap mt-auto align-self-start">Перейти <i className="ms-1 bi-arrow-right-circle"></i></Button>
						</Card.Body>
					</Card>
				</Col>
				<Col md sm={6} xs={12} className="d-flex align-items-stretch mb-3">
					<Card>
						<Card.Body className="d-flex flex-column">
							<Card.Title><i className="bi-bag"></i> Продукты</Card.Title>
							<Card.Text>
								Приложения, в которых используются модули логирования и лицензирования.
							</Card.Text>
							<Button variant="light" href="/product" className="text-nowrap mt-auto align-self-start">Перейти <i className="ms-1 bi-arrow-right-circle"></i></Button>
						</Card.Body>
					</Card>
				</Col>
				<Col md sm={6} xs={12} className="d-flex align-items-stretch mb-3">
					<Card>
						<Card.Body className="d-flex flex-column">
							<Card.Title><i className="bi-person"></i> Пользователи</Card.Title>
							<Card.Text>
								Администраторы панели управления и пользователи приложений, для которых выпускаются лицензии.
							</Card.Text>
							<Button variant="light" href="/user" className="text-nowrap mt-auto align-self-start">Перейти <i className="ms-1 bi-arrow-right-circle"></i></Button>
						</Card.Body>
					</Card>
				</Col>
				<Col md sm={6} xs={12} className="d-flex align-items-stretch mb-3">
					<Card>
						<Card.Body className="d-flex flex-column">
							<Card.Title><i className="bi-people"></i> Организации</Card.Title>
							<Card.Text>
								Организации или группы пользователей, использующих лицензируемое программное обеспечение.
							</Card.Text>
							<Button variant="light" href="/company" className="text-nowrap mt-auto align-self-start">Перейти <i className="ms-1 bi-arrow-right-circle"></i></Button>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col lg md={12} sm={12} xs={12} className="d-flex align-items-stretch mb-3">
					<Card>
						<Card.Body className="d-flex flex-column">
							<Card.Title className="ms-3">Виды лицензий</Card.Title>
							<ListGroup variant="flush">
								<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
									<div className="me-auto">
										Неограниченная
										<div className="text-muted">Может использоваться неограниченно по времени и по количеству запусков.</div>
									</div>
								</ListGroup.Item>
								<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
									<div className="me-auto">
										Временная
										<div className="text-muted">Лицензия с ограниченным периодом использования. Отсчет времени идет с момента создания.</div>
									</div>
								</ListGroup.Item>
								<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
									<div className="me-auto">
										Временная, с момента активации
										<div className="text-muted">Лицензия с ограниченным периодом использования. Отсчет времени идет с момента первой активации приложения.</div>
									</div>
								</ListGroup.Item>
								<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
									<div className="me-auto">
										По количеству запусков
										<div className="text-muted">Лицензия с ограниченным периодом использования. При каждом запуске приложения проверяется количество оставшихся запусков.</div>
									</div>
								</ListGroup.Item>
							</ListGroup>
						</Card.Body>
					</Card>
				</Col>
				<Col lg md={12} sm={12} xs={12} className="d-flex align-items-stretch mb-3">
					<Card>
						<Card.Body className="d-flex flex-column">
							<Card.Title className="ms-3">Доступная статистика</Card.Title>
							<ListGroup variant="flush">
								<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
									<div className="me-auto">
										Статистика использования лицензий
										<div className="text-muted">Отображение информации об активных лицензий, доступных активациях, оставшемся времени и запусков ПО.</div>
									</div>
								</ListGroup.Item>
								<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
									<div className="me-auto">
										Статистика по логам
										<div className="text-muted">Вывод логов запусков приложений с сервера Elastic.</div>
									</div>
								</ListGroup.Item>
								<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
									<div className="me-auto">
										Статистика по ошибкам
										<div className="text-muted">Вывод графиков и записей об ошибках системы лицензирования и логирования, получаемых с сервера Elastic.</div>
									</div>
								</ListGroup.Item>
							</ListGroup>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}

export default Dashboard;

// row justify-content-center