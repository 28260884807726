import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom';

import routes from 'Config/routes';
import { AuthProvider } from 'Context';
import AppRoute from 'Components/AppRoute';
import Header from 'Common/header';
import Footer from 'Common/footer';

function App() {
	return (
		<AuthProvider>
			<Header />
			<Router>
				<Switch>
					<Route exact path='/'>
						<Redirect to='/dashboard' />
					</Route>
					{routes.map((route) => (
						<AppRoute
							key={route.path}
							path={route.path}
							component={route.component}
							isPrivate={route.isPrivate}
						/>
					))}
				</Switch>
			</Router>
			<Footer />
		</AuthProvider>
	);
}

export default App;
