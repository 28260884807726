import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FloatingLabel, Form, Button, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { GetLicense, SetLicenseCounter, EditLicense } from 'Api';
import { getLicenseType } from 'Helpers';
import NotFound from 'Components/NotFound';
import Loading from 'Components/Loading';
import { convertLifetime, ShowLicenseTypeBlock } from './common';

function LicenseEdit(props) {
	const [license, setLicense] = useState('');
	const [loading, setLoading] = useState(true);

	const params = useParams();

	const { register, handleSubmit, formState: { errors }, reset, formState } = useForm();
	const { isSubmitting } = formState;

	useEffect(() => {
		(async () => {
			const license = await GetLicense(params.licenseId);
			setLicense(license);

			setLoading(false);
		})();
	}, [params.licenseId]);

	if (loading) {
		return <Loading />;
	}

	const onSubmit = async (data, e) => {
		e.preventDefault();

		switch (license.license_type) {
			case 'temporary':
			case 'temporary_after_activation':
				data.lifetime = convertLifetime(data.lifetime, data.lifetimeType);

				await EditLicense(data).then(response => {
					e.target.reset();

		 			props.history.push('/license');
				}).catch(error => {
					console.log('Response "license.edit" error:', error);
				});
				break;

			case 'countered':
				await SetLicenseCounter(data).then(response => {
					e.target.reset();

		 			props.history.push('/license');
				}).catch(error => {
					console.log('Response "license.set_counter" error:', error);
				});
				break;

			default:
				console.log('Unknown license type');
				return;
		}
	};

	const onReset = () => {
		reset();
	};

	if (license && !license.is_deleted) {
		return (
			<div className="container-fluid">
				<h3>Изменение лицензии - {license.id}</h3>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Control type="hidden" defaultValue={license.product_id} {...register('productId')} />
					<Form.Control type="hidden" defaultValue={license.user_id} {...register('userId')} />
					<Row>
						<Col md>
							<FloatingLabel label="Продукт" className="mb-2">
								<Form.Control defaultValue={license._product.name} readOnly />
							</FloatingLabel>
						</Col>
						<Col md>
							<FloatingLabel label="ID" className="mb-2">
								<Form.Control defaultValue={license.id} readOnly />
							</FloatingLabel>
						</Col>
					</Row>
					<Row>
						<Col md>
							<FloatingLabel label="Пользователь" className="mb-2">
								<Form.Control defaultValue={license._user.username} readOnly />
							</FloatingLabel>
						</Col>
						<Col md>
							<FloatingLabel label="Дата создания" className="mb-2">
								<Form.Control defaultValue={license.create_date} readOnly />
							</FloatingLabel>
						</Col>
					</Row>
					<Row>
						<Col md>
							<FloatingLabel label="Тип лицензии" className="mb-2">
								<Form.Control defaultValue={getLicenseType(license.license_type)} readOnly />
							</FloatingLabel>
						</Col>
						<Col md>
							<FloatingLabel label="Количество активаций" className="mb-2">
								<Form.Control defaultValue={license.activation_count} readOnly />
							</FloatingLabel>
						</Col>
					</Row>
					<hr />

					<ShowLicenseTypeBlock dataBlock={license.license_type} register={register} errors={errors} license={license} />

					<Button variant="outline-success" type="submit" className="me-2">{isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}Изменить</Button>
					<Button variant="outline-danger" type="reset" onClick={onReset}>Сбросить</Button>
				</Form>
			</div>
		);
	} else {
		return <NotFound />;
	}
}

export default LicenseEdit;
