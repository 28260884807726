export const apiConfig = {
	actions: {
		user: {
			login: {
				url:    'User/SignIn',
				method: 'POST',
			},
			info: {
				url:    'User/GetInfo',
				method: 'GET',
			},
			list: {
				url:    'User/page',
				method: 'GET',
			},
			role: {
				url:    'User/role',
				method: 'GET',
			},
			add: {
				url:    'User/SignUp',
				method: 'POST',
			},
		},
		license: {
			list: {
				url:    'License/page',
				method: 'GET',
			},
			types: {
				url:    'License/types',
				method: 'GET',
			},
			add: {
				url:    'License/add',
				method: 'POST',
			},
			set_counter: {
				url:    'License/set_counter',
				method: 'POST',
			},
			delete: {
				url:    'License/delete',
				method: 'POST',
			},
			edit: {
				url:    'License/update',
				method: 'POST',
			},
		},
		product: {
			list: {
				url:    'Product/GetPaging',
				method: 'GET',
			},
			add: {
				url:    'Product/AddProduct',
				method: 'POST',
			},
			get: {
				url:    'Product/GetInfo',
				method: 'GET',
			},
			delete: {
				url:    'Product/{id}',
				method: 'DELETE',
			},
			edit: {
				url:    'Product/{id}',
				method: 'PUT',
			},
			restore: {
				url:    'Product/Restore/{id}',
				method: 'URL_PARAM_POST',
			},
		},
		company: {
			list: {
				url:    'User/page',
				method: 'GET',
			},
			add: {
				url:    'User/SignUpCorp',
				method: 'POST',
			},
		},
	}
};
