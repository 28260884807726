import React from 'react';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { ApiRequest } from 'Api';

function CompanyAdd(props) {
	const { register, handleSubmit, formState: { errors }, reset, formState } = useForm();
	const { isSubmitting } = formState;

	const onSubmit = async (data, e) => {
		e.preventDefault();

		try {
			await ApiRequest('company.add', data)
				.then(response => {
					e.target.reset();

				 	props.history.push('/company/view/' + response.data.email);
				})
				.catch(error => {
					console.log('Response "company.add" error:', error);
					document.getElementById('errorMessage').innerHTML = 'Не удалось создать организацию. Возможно, организация с таким email уже существует.';
				});
		} catch(error) {
			console.log(error);
		}
	};

	const onReset = () => {
		reset();
	};

	return (
		<div className="container-fluid">
			<h3>Создание организации</h3>
			<div className="text-danger mb-2" id="errorMessage"></div>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<FloatingLabel label="Название компании" className="mb-2">
					<Form.Control
						{...register('userName', {
							required:  'Поле необходимо заполнить',
							maxLength: {
								value:   80,
								message: 'Длина названия организации не может быть больше 80 символов',
							},
						})}
						placeholder="Название компании"
					/>
					{errors.userName && <div className="text-danger">{errors.userName.message}</div>}
				</FloatingLabel>
				<FloatingLabel label="Email" className="mb-2">
					<Form.Control
						{...register('email', {
							required:  'Поле необходимо заполнить',
							maxLength: {
								value:   254,
								message: 'Длина email не может быть больше 254 символов',
							},
							pattern: {
								value:   /\S+@\S+\.\S+/,
								message: 'Введенный email не является корректным',
							},
							onChange:  () => document.getElementById('errorMessage').innerHTML = '',
						})}
						placeholder="Email"
						type="email"
					/>
					{errors.email && <div className="text-danger">{errors.email.message}</div>}
				</FloatingLabel>
				<FloatingLabel label="Пароль" className="mb-2">
					<Form.Control
						{...register('password', {
							required:  'Поле необходимо заполнить',
							minLength: {
								value:   6,
								message: 'Пароль должен быть не менее 6 символов',
							},
							maxLength: {
								value:   80,
								message: 'Пароль должен быть не более 80 символов',
							},
						})}
						placeholder="Пароль"
					/>
					{errors.password && <div className="text-danger">{errors.password.message}</div>}
				</FloatingLabel>
				<FloatingLabel label="Максимум пользователей в организации" className="mb-2">
					<Form.Control
						{...register('maxChildren', {
							required:  'Поле необходимо заполнить',
							minLength: {
								value:   1,
								message: 'Минимальное количество пользователей в организации не может быть меньше 1',
							},
							maxLength: {
								value:   3,
								message: 'Максимальное количество пользователей в организации не может превышать 999',
							},
						})}
						placeholder="Максимум пользователей в организации"
						type="number"
						defaultValue="999"
					/>
					{errors.maxChildren && <div className="text-danger">{errors.maxChildren.message}</div>}
				</FloatingLabel>
				<Button variant="outline-success" type="submit" className="me-2">{isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}Создать</Button>
				<Button variant="outline-danger" type="reset" onClick={onReset}>Сбросить</Button>
			</Form>
		</div>
	);
}

export default CompanyAdd;
