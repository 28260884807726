import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table as BTable, Alert } from 'react-bootstrap';

import { getLicenseType } from 'Helpers';
import { GetLicense } from 'Api';
import NotFound from 'Components/NotFound';
import Loading from 'Components/Loading';

function LicenseView(props) {
	const [license, setLicense] = useState('');
	const [loading, setLoading] = useState(true);

	const params = useParams();

	useEffect(() => {
		(async () => {
			const license = await GetLicense(params.licenseId);
			setLicense(license);

			setLoading(false);
		})();
	}, [params.licenseId]);

	if (loading) {
		return <Loading />;
	}

	if (license) {
		return (
			<div className="container-fluid">
				<h3>Просмотр лицензии - {license.id}</h3>
				<BTable>
					<tbody>
						<tr>
							<td>ID</td>
							<td>{license.id}</td>
						</tr>
						<tr>
							<td>Продукт</td>
							<td>
								{license._product
									? <Alert.Link href={'/product/view/' + license._product.id}>{license._product.name}</Alert.Link>
									: <span>Неизвестен (productId: {license.product_id})</span>
								}
							</td>
						</tr>
						<tr>
							<td>Пользователь</td>
							<td>{license._user
									? <Alert.Link href={'/user/view/' + license._user.id}>{license._user.username}</Alert.Link>
									: <span>Неизвестен (userId: {license.user_id})</span>
								}
							</td>
						</tr>
						<tr>
							<td>Дата создания</td>
							<td>{license.create_date}</td>
						</tr>
						<tr>
							<td>Тип лицензии</td>
							<td>{getLicenseType(license.license_type)}</td>
						</tr>
						{license.license_type === 'temporary' || license.license_type === 'temporary_after_activation'
							?
								<tr>
									<td>Осталось времени</td>
									<td>{license.time_left}</td>
								</tr>
							: null
						}
						{license.license_type === 'countered'
							?
								<tr>
									<td>Осталось запусков</td>
									<td>{license.counters_left}</td>
								</tr>
							: null
						}
						<tr>
							<td>Количество активаций</td>
							<td>{license.activation_count}</td>
						</tr>
						<tr>
							<td>Статус</td>
							<td>{license.is_deleted === true ? 'Удалена' : 'Активна'}</td>
						</tr>
					</tbody>
				</BTable>
			</div>
		);
	} else {
		return <NotFound />;
	}
}

export default LicenseView;
