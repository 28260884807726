import React from 'react';
import Button from 'react-bootstrap/Button';

function NotFound(props) {
	return (
		<div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
			<h1>404</h1>
			<p>Такой страницы не существует</p>
			<p><Button variant="outline-primary" href="/">Вернуться на главную страницу</Button></p>
		</div>
	);
}

export default NotFound;
