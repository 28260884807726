import React, { useState, useEffect } from 'react';

import Table from 'Components/Table';
import { GetProductList, DeleteProduct, RestoreProduct } from 'Api';
import Loading from 'Components/Loading';
import EditButton from 'Components/Buttons/EditButton';
import ViewButton from 'Components/Buttons/ViewButton';
import DeleteRestoreButton from 'Components/Buttons/DeleteRestoreButton';

const tableHead = [
	{ accessor: 'id',          Header: 'ID' },
	{ accessor: 'name',        Header: 'Название' },
	{ accessor: 'description', Header: 'Описание' },
	{ accessor: 'actions',     Header: 'Действие' },
];

function ProductList(props) {
	const [productList, setProductList] = useState('');

	useEffect(() => {
		(async () => {
			const productList = await GetProductList({ withDeleted: true });
			setProductList(productList.items);
		})();
	}, []);

	if (productList) {
		let preparedProductList = [];

		let length = productList.length;
		for (var i = 0; i < length; i++) {
			preparedProductList[i] = {
				id:          productList[i].id,
				name:        productList[i].name,
				description: productList[i].description,
				lineThrough: productList[i].isDeleted,
				actions:     <>
								<ViewButton
									href={'/product/view/' + productList[i].id}
									size="sm"
									title={'Просмотр продукта ' + productList[i].name}
								/>
								<EditButton
									href={'/product/edit/' + productList[i].id}
									size="sm"
									title={'Изменить продукт ' + productList[i].name}
								/>
								<DeleteRestoreButton
									id={productList[i].id}
									actionDelete={DeleteProduct}
									actionRestore={RestoreProduct}
									name="продукт"
									hasRestore={true}
									size="sm"
									entityName={productList[i].name}
									title={true}
									isDeleted={productList[i].isDeleted}
								/>
							 </>,
			};
		}

		return (
			<div className="container-fluid">
				<h3>Список продуктов</h3>
				<Table columns={tableHead} data={preparedProductList} />
			</div>
		);
	} else {
		return <Loading />;
	}
}

export default ProductList;
