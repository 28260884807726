import React, { useState, useEffect } from 'react';
import { Table as BTable } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { GetProduct, DeleteProduct, RestoreProduct } from 'Api';
import NotFound from 'Components/NotFound';
import Loading from 'Components/Loading';
import EditButton from 'Components/Buttons/EditButton';
import DeleteRestoreButton from 'Components/Buttons/DeleteRestoreButton';

function ProductView(props) {
	const [product, setProduct] = useState('');
	const [loading, setLoading] = useState(true);

	const params = useParams();

	useEffect(() => {
		(async () => {
			const product = await GetProduct(params.productId);
			setProduct(product);

			setLoading(false);
		})();
	}, [params.productId]);

	if (loading) {
		return <Loading />;
	}

	if (product) {
		return (
			<div className="container-fluid">
				<h3>Просмотр продукта - {product.name}</h3>
				<BTable>
					<thead>
						<tr>
							<td colSpan="2">
								<EditButton
									href={'/product/edit/' + product.id}
									className="ms-n2"
									withCaption={true}
								/>
								<DeleteRestoreButton
									id={product.id}
									actionDelete={DeleteProduct}
									actionRestore={RestoreProduct}
									name="продукт"
									hasRestore={true}
									entityName={product.name}
									title={true}
									isDeleted={product.isDeleted}
									withCaption={true}
								/>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>ID</td>
							<td>{product.id}</td>
						</tr>
						<tr>
							<td>Название</td>
							<td>{product.name}</td>
						</tr>
						<tr>
							<td>Описание</td>
							<td>{product.description}</td>
						</tr>
						<tr>
							<td>Статус</td>
							<td>{product.isDeleted === true ? 'Удален' : 'Активен'}</td>
						</tr>
					</tbody>
				</BTable>
			</div>
		);
	} else {
		return <NotFound />;
	}
}

export default ProductView;
