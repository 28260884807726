export const getJwtToken = function() {
	const jwtTokenInfo = JSON.parse(localStorage.getItem('jwtToken'));
	if (jwtTokenInfo === null || jwtTokenInfo.jwtToken === null) {
		return null;
	}

	let date = new Date();
	let now  = date.setDate(date.getDate());
	if (jwtTokenInfo.expireDate <= now) {
		removeJwtToken();
		return null;
	}

	return jwtTokenInfo.jwtToken;
}

export const setJwtToken = function(jwtToken) {
	let date = new Date();
	const jwtTokenInfo = {
		jwtToken:   jwtToken,
		expireDate: date.setDate(date.getDate()) + 60 * 60 * 24 * 1000,
	};

	localStorage.setItem('jwtToken', JSON.stringify(jwtTokenInfo));
}

export const removeJwtToken = function() {
	localStorage.removeItem('jwtToken');
}

export const getCurrentUser = function() {
	const currentUser = JSON.parse(localStorage.getItem('currentUser'));
	if (currentUser === null || currentUser.id === null) {
		return null;
	}

	return currentUser;
}

export const setCurrentUser = function(currentUser) {
	localStorage.setItem('currentUser', JSON.stringify(currentUser));
}

export const removeCurrentUser = function() {
	localStorage.removeItem('currentUser');
}
