import Login from 'Pages/Login';

import Dashboard from 'Pages/Dashboard';

import NotFound from 'Components/NotFound';

import ProductList from 'Pages/Product';
import ProductView from 'Pages/Product/view';
import ProductAdd from 'Pages/Product/add';
import ProductEdit from 'Pages/Product/edit';

import UserList from 'Pages/User';
import UserView from 'Pages/User/view';
import UserAdd from 'Pages/User/add';

import LicenseList from 'Pages/License';
import LicenseView from 'Pages/License/view';
import LicenseAdd from 'Pages/License/add';
import LicenseEdit from 'Pages/License/edit';

import CompanyList from 'Pages/Company';
import CompanyAdd from 'Pages/Company/add';
import CompanyView from 'Pages/Company/view';

import StatsLicense from 'Pages/Stats/license';
import StatsLogs from 'Pages/Stats/logs';
import StatsErrors from 'Pages/Stats/errors';

const routes = [
	{
		path:      '/login',
		component: Login,
		isPrivate: false,
	},
	{
		path:      '/dashboard',
		component: Dashboard,
		isPrivate: true,
	},
	{
		path:      '/license/add',
		component: LicenseAdd,
		isPrivate: true,
	},
	{
		path:      '/license/view/:licenseId',
		component: LicenseView,
		isPrivate: true,
	},
	{
		path:      '/license/edit/:licenseId',
		component: LicenseEdit,
		isPrivate: true,
	},
	{
		path:      '/license',
		component: LicenseList,
		isPrivate: true,
	},
	{
		path:      '/product/view/:productId',
		component: ProductView,
		isPrivate: true,
	},
	{
		path:      '/product/edit/:productId',
		component: ProductEdit,
		isPrivate: true,
	},
	{
		path:      '/product/add',
		component: ProductAdd,
		isPrivate: true,
	},
	{
		path:      '/product',
		component: ProductList,
		isPrivate: true,
	},
	{
		path:      '/user/add',
		component: UserAdd,
		isPrivate: true,
	},
	{
		path:      '/user/view/:userId',
		component: UserView,
		isPrivate: true,
	},
	{
		path:      '/user',
		component: UserList,
		isPrivate: true,
	},
	{
		path:      '/company/add',
		component: CompanyAdd,
		isPrivate: true,
	},
	{
		path:      '/company/view/:companyId',
		component: CompanyView,
		isPrivate: true,
	},
	{
		path:      '/company',
		component: CompanyList,
		isPrivate: true,
	},
	{
		path:      '/stats/license',
		component: StatsLicense,
		isPrivate: true,
	},
	{
		path:      '/stats/logs',
		component: StatsLogs,
		isPrivate: true,
	},
	{
		path:      '/stats/errors',
		component: StatsErrors,
		isPrivate: true,
	},
	{
		path:      '/*',
		component: NotFound,
		isPrivate: false,
	},
];

export default routes;
