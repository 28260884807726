import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, FloatingLabel, Button } from 'react-bootstrap';

import { loginUser, useAuthState, useAuthDispatch } from 'Context';

function Login(props) {
	const dispatch  = useAuthDispatch();
	const authState = useAuthState();

	const [email, setEmail]       = useState('');
	const [password, setPassword] = useState('');

	if (authState.isAuthenticated) {
		return <Redirect push to="/dashboard" />;
	}

	const handleLogin = async (e) => {
		e.preventDefault();

		try {
			await loginUser(dispatch, { email, password });
		} catch(error) {
			console.log(error);
		}
	};

	function handleEnterKey(e) {
		if (e.key === 'Enter') {
			handleLogin(e);
		}
	}

	return (
		<Form onKeyDown={handleEnterKey} onSubmit={handleLogin} className="form-signin text-center auth_form">
			<h1 className="h3 mb-3 fw-normal">Авторизация</h1>

			<FloatingLabel label="Email">
				<Form.Control
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					disabled={authState.loading}
					placeholder="name@domain.ru"
					required
				/>
			</FloatingLabel>
			<FloatingLabel label="Пароль">
				<Form.Control
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					disabled={authState.loading}
					placeholder="Пароль"
					required
				/>
			</FloatingLabel>

			{authState.errorMessage
				? <div id="errorMessage" className="text-danger">{authState.errorMessage}</div>
				: null
			}

			<br />

			<Button variant="primary" size="lg" className="w-100" type="submit" disabled={authState.loading}>Войти</Button>
		</Form>
	);
}

export default Login;
