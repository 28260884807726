import React, { useState, useEffect } from 'react';

import Table from 'Components/Table';
import { GetUserList } from 'Api';
import Loading from 'Components/Loading';
import ViewButton from 'Components/Buttons/ViewButton';

const tableHead = [
	{ accessor: 'id',        Header: 'ID' },
	{ accessor: 'username',  Header: 'Пользователь' },
	{ accessor: 'email',     Header: 'Email' },
	{ accessor: 'is_banned', Header: 'Заблокирован' },
	{ accessor: 'actions',   Header: 'Действие' },
];

function UserList(props) {
	const [userList, setUserList] = useState('');

	useEffect(() => {
		(async () => {
			const userList = await GetUserList();
			setUserList(userList.items);
		})();
	}, []);

	if (userList) {
		let preparedUserList = [];

		let length = userList.length;
		for (var i = 0; i < length; i++) {
			preparedUserList[i] = {
				id:          userList[i].id,
				username:    userList[i].username,
				email:       userList[i].email,
				lineThrough: userList[i].is_banned,
				actions:     <ViewButton
								href={'/user/view/' + userList[i].id}
								size="sm"
								title={'Просмотр пользователя ' + userList[i].username}
							 />,
			};
		}

		return (
			<div className="container-fluid">
				<h3>Список пользователей</h3>
				<Table columns={tableHead} data={preparedUserList} />
			</div>
		);
	} else {
		return <Loading />;
	}
}

export default UserList;
