import axios from 'axios';

import { apiConfig } from 'Config/api';
import { urlConfig } from 'Config/envSettings';
import { getDeepObjectValue } from 'Helpers';
import * as session from 'Session';

const client = axios.create({
	baseURL: urlConfig.baseURL
});

const ApiRequest = async (action, payload) => {
	const doRequest = (api, method, payload) => {
		let headers = {
			'Content-Type': 'application/json-patch+json',
			'Accept':       'application/json',
		};

		const jwtToken = session.getJwtToken();
		if (jwtToken && jwtToken !== null) {
			headers.Authorization = 'Bearer ' + jwtToken;
		}

		const options = {
			headers: headers,
		}

		switch (method) {
			case 'POST':
				return client.post(api, payload, options).then(response => response);

			case 'GET':
				if (payload) {
					api += '?' + new URLSearchParams(payload).toString();
				}
				return client.get(api, options).then(response => response);

			case 'PUT':
				if (payload.hasOwnProperty('id')) {
					api = api.replace(/{id}/g, payload.id);
					delete payload.id;
				} else {
					return {
						error:       'put_hasnt_id',
						description: 'Cannot do request PUT, no ID provided.'
					}
				}
				return client.put(api, payload, options).then(response => response);

			case 'DELETE':
				if (payload.hasOwnProperty('id')) {
					api = api.replace(/{id}/g, payload.id);
				} else {
					return {
						error:       'delete_hasnt_id',
						description: 'Cannot do request DELETE, no ID provided.'
					}
				}
				return client.delete(api, options).then(response => response);

			case 'PATCH':
				if (payload.hasOwnProperty('id')) {
					api = api.replace(/{id}/g, payload.id);
				} else {
					return {
						error:       'patch_hasnt_id',
						description: 'Cannot do request PATCH, no ID provided.'
					}
				}
				return client.patch(api, options).then(response => response);

			// да, это немного костыль, потому что реализация выше не предусматривает случай,
			// когда id из payload складывается в url при post-запросе,
			// как это должно происходить в случае с Restore продукта;
			// некостыльное решение - переписать весь api-клиент.
			case 'URL_PARAM_POST':
				if (payload.hasOwnProperty('id')) {
					api = api.replace(/{id}/g, payload.id);
				} else {
					return {
						error:       'url_param_post_hasnt_id',
						description: 'Cannot do request URL_PARAM_POST, no ID provided.'
					}
				}
				return client.post(api, null, options).then(response => response);

			default:
				return {
					error:       'unknown_method',
					description: 'Unknown method to call request.'
				}
		}
	}

	const apiAction = 'actions.' + action;

	const actionData = getDeepObjectValue(apiConfig, apiAction);

	if (actionData !== undefined) {
		const response = doRequest(actionData.url, actionData.method, payload);
		return await response;
	} else {
		return {
			error:       'unknown_api_action',
			description: 'Unknown API action "' + action + '".'
		}
	}
}

export default ApiRequest;
