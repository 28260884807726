import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { GetProduct } from 'Api';
import NotFound from 'Components/NotFound';
import { ApiRequest } from 'Api';

function ProductView(props) {
	const [product, setProduct] = useState('');

	const { register, handleSubmit, formState: { errors }, reset, formState } = useForm();
	const { isSubmitting } = formState;

	const params = useParams();

	useEffect(() => {
		(async () => {
			const product = await GetProduct(params.productId);
			setProduct(product);
		})();
	}, [params.productId]);

	const onSubmit = async (data, e) => {
		e.preventDefault();

		try {
			await ApiRequest('product.edit', data)
				.then(response => {
					e.target.reset();

				 	props.history.push('/product/view/' + response.data.entity.id);
				})
				.catch(error => {
					console.log('Response "product.edit" error:', error);
				});
		} catch(error) {
			console.log(error);
		}
	};

	const onReset = () => {
		reset();
	};

	if (product) {
		return (
			<div className="container-fluid">
				<h3>Изменение продукта - {product.name}</h3>
				<div className="text-danger mb-2" id="errorMessage"></div>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Control {...register('id')} type="hidden" defaultValue={product.id} />
					<FloatingLabel label="Название продукта" className="mb-2">
						<Form.Control
							{...register('name', {
								required:  'Поле необходимо заполнить',
								maxLength: {
									value:   80,
									message: 'Длина названия продукта не может быть больше 80 символов',
								},
								onChange:  () => document.getElementById('errorMessage').innerHTML = '',
							})}
							defaultValue={product.name}
							placeholder="Название продукта"
						/>
						{errors.name && <div className="text-danger">{errors.name.message}</div>}
					</FloatingLabel>
					<FloatingLabel label="Описание продукта" className="mb-2">
						<Form.Control
							{...register('description', { maxLength: 1000 })}
							defaultValue={product.description}
							placeholder="Описание продукта"
						/>
					</FloatingLabel>
					<Button variant="outline-success" type="submit" className="me-2">{isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}Изменить</Button>
					<Button variant="outline-danger" type="reset" onClick={onReset}>Сбросить</Button>
				</Form>
			</div>
		);
	} else {
		return <NotFound />;
	}
}

export default ProductView;
