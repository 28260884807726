import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

import Table from 'Components/Table';
import { getLicenseType, getUserFromUserList, getProductFromProductList } from 'Helpers';
import { GetProductList, GetUserList, GetLicenseList } from 'Api';
import Loading from 'Components/Loading';
import ViewButton from 'Components/Buttons/ViewButton';

const tableHead = [
	{ accessor: 'id',               Header: 'ID' },
	{ accessor: 'product_link',     Header: 'Продукт' },
	{ accessor: 'user_link',        Header: 'Пользователь' },
	{ accessor: 'create_date',      Header: 'Дата создания' },
	{ accessor: 'license_type',     Header: 'Тип лицензии' },
	{ accessor: 'activation_count', Header: 'Количество активаций' },
	{ accessor: 'time_left',        Header: 'Осталось времени' },
	{ accessor: 'counters_left',    Header: 'Осталось запусков' },
	{ accessor: 'actions',          Header: 'Действие' },
];

function StatsLicense(props) {
	const [licenseList, setLicenseList] = useState('');
	const [userList, setUserList]       = useState('');
	const [productList, setProductList] = useState('');

	useEffect(() => {
		(async () => {
			const productList = await GetProductList({ withDeleted: true });
			setProductList(productList.items);

			const userList = await GetUserList();
			setUserList(userList.items);

			const licenseList = await GetLicenseList({ onlyActive: true, withLifetime: true });
			setLicenseList(licenseList.items);
		})();
	}, []);

	if (licenseList) {
		let preparedLicenseList = [];

		let length = licenseList.length;
		for (var i = 0; i < length; i++) {
			let licenseProduct = getProductFromProductList(productList, licenseList[i].product_id);
			let licenseUser    = getUserFromUserList(userList, licenseList[i].user_id);

			preparedLicenseList[i] = {
				id:               licenseList[i].id,
				product_link:     licenseProduct
									? <Alert.Link href={'/product/view/' + licenseProduct.id}>{licenseProduct.name}</Alert.Link>
									: <span>Неизвестен<br />(productId: {licenseList[i].product_id})</span>,
				user_link:        licenseUser
									? <Alert.Link href={'/user/view/' + licenseUser.id}>{licenseUser.username}</Alert.Link>
									: <span>Неизвестен<br />(userId: {licenseList[i].user_id})</span>,
				create_date:      licenseList[i].create_date,
				license_type:     getLicenseType(licenseList[i].license_type),
				activation_count: licenseList[i].activation_count,
				time_left:        licenseList[i].time_left === '356000.00:00:00' ? '∞' : licenseList[i].time_left,
				counters_left:    licenseList[i].counters_left,
				actions:          <ViewButton
									href={'/license/view/' + licenseList[i].id}
									title={'Просмотр лицензии ' + licenseList[i].id}
									size="sm"
								  />,
			};
		}

		return (
			<div className="container-fluid">
				<h3>Статистика использования лицензий</h3>
				<Table columns={tableHead} data={preparedLicenseList} />
			</div>
		);
	} else {
		return <Loading />;
	}
}

export default StatsLicense;
