import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';

import { capitalizeFirst } from 'Helpers';

const DeleteRestoreButton = (props) => {
	const type = props.hasOwnProperty('type')
					? props.type
					: props.hasOwnProperty('isDeleted')
						? props.isDeleted === true ? 'restore' : 'delete'
						: 'delete';

	const hasRestore = props.hasOwnProperty('hasRestore') ? props.hasRestore : false;

	if (type === 'restore' && hasRestore === false) {
		return null;
	}

	if (document.getElementsByTagName('body')[0].className.match(/color-scheme-dark/)) {
		require('@sweetalert2/theme-dark/dark.css');
	}

	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton: 'btn btn-outline-success',
			cancelButton:  'btn btn-outline-danger ms-2',
		},
		buttonsStyling: false,
	});

	const titles = {
		to_restore:   'восстановить',
		to_delete:    'удалить',
		done_restore: 'восстановлено',
		done_delete:  'удалено',
	};

	const confirmBox = () => {
		swalWithBootstrapButtons.fire({
			title:             'Действительно ' + titles['to_' + type] + ' ' + (props.name ? props.name : '') + '?',
			text:              hasRestore ? 'После можно будет восстановить.' : 'Действие нельзя отменить!',
			icon:              'warning',
			showCancelButton:  true,
			confirmButtonText: 'Да, ' + titles['to_' + type],
			cancelButtonText:  'Нет, отмена',
			reverseButtons:    false,
			preConfirm:        (id) => {
									var inputData = props.hasOwnProperty('actionData') && props.actionData ? props.actionData : props.id;

									return props['action' + capitalizeFirst(type)](inputData).then(response => {
										return { done: (Boolean(response) ? true : false) };
									});
								}
		}).then(result => {
			if (result.isConfirmed) {
				if (result.value.done) {
					swalWithBootstrapButtons.fire(
						capitalizeFirst(titles['done_' + type]),
						'',
						'success',
					);

					const container = document.getElementById(type + '_' + props.id);
					const row = container.closest('tr');
					var isDeletedNew, typeNew;
					if (type === 'delete') {
						typeNew = 'restore';
						isDeletedNew = true;
						row.classList.add('text-decoration-line-through');
					} else {
						typeNew = 'delete';
						isDeletedNew = false;
						row.classList.remove('text-decoration-line-through');
					}

					// Warning: render(...): Replacing React-rendered children with a new root component.
					// If you intended to update the children of this node, you should instead have the existing
					// children update their state and render the new components instead of calling ReactDOM.render.
					// TODO: подумать как заменить render на что-то другое
					ReactDOM.render(
						<DeleteRestoreButton {...props}
							isDeleted={isDeletedNew}
							type={typeNew}
						/>, container
					);
				} else {
					swalWithBootstrapButtons.fire(
						'Ошибка',
						'Не удалось ' + titles['to_' + type],
						'error',
					)
				}
			} /*else if (result.dismiss === Swal.DismissReason.cancel) {
				swalWithBootstrapButtons.fire(
					'Отмена',
					'Действие отменено',
					'error',
				);
			}*/
		});
	}

	return (
		<span id={type + '_' + props.id} className="me-1">
			<Button
				onClick={confirmBox}
				variant={type === 'delete' ? 'outline-danger' : 'outline-success'}
				className={`${props.className ? props.className + ' ' : ''}`}
				size={props.size ? props.size : ''}
				title={
					props.title
						?
							props.name
								?
									props.entityName
										? capitalizeFirst(titles['to_' + type]) + ' ' + props.name + ' ' + props.entityName
										: capitalizeFirst(titles['to_' + type]) + ' ' + props.name
								: capitalizeFirst(titles['to_' + type])
						: null
				}
			>
				<i className={type === 'delete' ? 'bi-trash' : 'bi-arrow-clockwise'}></i>
				{props.withCaption ? ' ' + capitalizeFirst(titles['to_' + type]) : ''}
			</Button>
		</span>
	);
}

export default DeleteRestoreButton;
