import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

import Table from 'Components/Table';
import { getLicenseType, getUserFromUserList, getProductFromProductList } from 'Helpers';
import { GetProductList, GetUserList, GetLicenseList, DeleteLicense } from 'Api';
import Loading from 'Components/Loading';
import ViewButton from 'Components/Buttons/ViewButton';
import EditButton from 'Components/Buttons/EditButton';
import DeleteRestoreButton from 'Components/Buttons/DeleteRestoreButton';

const tableHead = [
	{ accessor: 'id',               Header: 'ID' },
	{ accessor: 'product_link',     Header: 'Продукт' },
	{ accessor: 'user_link',        Header: 'Пользователь' },
	{ accessor: 'create_date',      Header: 'Дата создания' },
	{ accessor: 'license_type',     Header: 'Тип лицензии' },
	{ accessor: 'activation_count', Header: 'Количество активаций' },
	{ accessor: 'actions',          Header: 'Действие' },
];

function LicenseList(props) {
	const [licenseList, setLicenseList] = useState('');
	const [userList, setUserList]       = useState('');
	const [productList, setProductList] = useState('');

	useEffect(() => {
		(async () => {
			const productList = await GetProductList({ withDeleted: true });
			setProductList(productList.items);

			const userList = await GetUserList();
			setUserList(userList.items);

			const licenseList = await GetLicenseList({ onlyActive: false, withLifetime: false });
			setLicenseList(licenseList.items);
		})();
	}, []);

	if (licenseList) {
		let preparedLicenseList = [];

		let length = licenseList.length;
		for (var i = 0; i < length; i++) {
			let licenseProduct = getProductFromProductList(productList, licenseList[i].product_id);
			let licenseUser    = getUserFromUserList(userList, licenseList[i].user_id);

			preparedLicenseList[i] = {
				id:               licenseList[i].id,
				product_link:     licenseProduct
									? <Alert.Link href={'/product/view/' + licenseProduct.id}>{licenseProduct.name}</Alert.Link>
									: <span>Неизвестен<br />(productId: {licenseList[i].product_id})</span>,
				user_link:        licenseUser
									? <Alert.Link href={'/user/view/' + licenseUser.id}>{licenseUser.username}</Alert.Link>
									: <span>Неизвестен<br />(userId: {licenseList[i].user_id})</span>,
				create_date:      licenseList[i].create_date,
				license_type:     getLicenseType(licenseList[i].license_type),
				activation_count: licenseList[i].activation_count,
				lineThrough:      licenseList[i].is_deleted,
				actions:          <>
									<ViewButton
										href={'/license/view/' + licenseList[i].id}
										title={'Просмотр лицензии ' + licenseList[i].id}
										size="sm"
									/>
									{!licenseList[i].is_deleted && licenseList[i].license_type !== 'unlimited'
										?
											<EditButton
												href={'/license/edit/' + licenseList[i].id}
												size="sm"
												title={'Изменить лицензию ' + licenseList[i].id}
											/>
										: null
									}
									<DeleteRestoreButton
										id={licenseList[i].id}
										actionData={{ userId: licenseList[i].user_id, productId: licenseList[i].product_id }}
										actionDelete={DeleteLicense}
										name="лицензию"
										size="sm"
										entityName={licenseList[i].id}
										title={true}
										isDeleted={licenseList[i].is_deleted}
									/>
								  </>,
			};
		}

		return (
			<div className="container-fluid">
				<h3>Список лицензий</h3>
				<Table columns={tableHead} data={preparedLicenseList} />
			</div>
		);
	} else {
		return <Loading />;
	}
}

export default LicenseList;
