import React, { useState, useEffect } from 'react';
import { Table as BTable } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { GetCompany } from 'Api';
import NotFound from 'Components/NotFound';
import Loading from 'Components/Loading';

function CompanyView(props) {
	const [company, setCompany] = useState('');
	const [loading, setLoading] = useState(true);

	const params = useParams();

	useEffect(() => {
		(async () => {
			const company = await GetCompany(params.companyId);
			setCompany(company);

			setLoading(false);
		})();
	}, [params.companyId]);

	if (loading) {
		return <Loading />;
	}

	if (company) {
		return (
			<div className="container-fluid">
				<h3>Просмотр организации - {company.username}</h3>
				<BTable>
					<tbody>
						<tr>
							<td>ID</td>
							<td>{company.id}</td>
						</tr>
						<tr>
							<td>Название компании</td>
							<td>{company.username}</td>
						</tr>
						<tr>
							<td>Email</td>
							<td>{company.email}</td>
						</tr>
						<tr>
							<td>Максимум пользователей в организации</td>
							<td>{company.max_children}</td>
						</tr>
						<tr>
							<td>Статус</td>
							<td>{company.is_banned === true ? 'Заблокирован' : 'Активен'}</td>
						</tr>
					</tbody>
				</BTable>
			</div>
		);
	} else {
		return <NotFound />;
	}
}

export default CompanyView;
