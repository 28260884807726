import React from 'react';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { ApiRequest } from 'Api';

function ProductAdd(props) {
	const { register, handleSubmit, formState: { errors }, reset, formState } = useForm();
	const { isSubmitting } = formState;

	const onSubmit = async (data, e) => {
		e.preventDefault();

		try {
			await ApiRequest('product.add', data)
				.then(response => {
					e.target.reset();

				 	props.history.push('/product/view/' + response.data.id);
				})
				.catch(error => {
					if (error.response.data.error.includes('duplicate key value violates unique constraint')) {
						console.log('Response "product.add" error:', error);
						document.getElementById('errorMessage').innerHTML = 'Продукт "' + data.name + '" уже существует.';
					}
				});
		} catch(error) {
			console.log(error);
		}
	};

	const onReset = () => {
		reset();
	};

	return (
		<div className="container-fluid">
			<h3>Создание продукта</h3>
			<div className="text-danger mb-2" id="errorMessage"></div>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<FloatingLabel label="Название продукта" className="mb-2">
					<Form.Control
						{...register('name', {
							required:  'Поле необходимо заполнить',
							maxLength: {
								value:   80,
								message: 'Длина названия продукта не может быть больше 80 символов',
							},
							onChange:  () => document.getElementById('errorMessage').innerHTML = '',
						})}
						placeholder="Название продукта"
					/>
					{errors.name && <div className="text-danger">{errors.name.message}</div>}
				</FloatingLabel>
				<FloatingLabel label="Описание продукта" className="mb-2">
					<Form.Control
						{...register('description', { maxLength: 1000 })}
						placeholder="Описание продукта"
					/>
				</FloatingLabel>
				<Button variant="outline-success" type="submit" className="me-2">{isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}Создать</Button>
				<Button variant="outline-danger" type="reset" onClick={onReset}>Сбросить</Button>
			</Form>
		</div>
	);
}

export default ProductAdd;
